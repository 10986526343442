<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 图文 -->
      <ServiceIntro :info="info2" v-ani.fade-up />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc6_0.jpg'),
        //   title: '工程保函',
        //   intro: '了解需求，快速出函'
        // },
      ],
      info: {
        title: '工程保函',
        desc: '即招投标过程中要求投标人提供银行出具的保函，以担保投标材料真实、中标后不会因不正当理由不订立合同。担保金额一般为招标价的3%。',
        list: [
          { id: 1, title: '填写担保<br>申请书', desc: '提交招标文件' },
          { id: 2, title: '审查申请人<br>的申请内容', desc: '银行审查,符合规定' },
          { id: 3, title: '签订协议', desc: '双方签订协议,提供保函' },
          { id: 4, title: '银行为申请人<br>开立投标保函', desc: '开立投标保，自开业之日起生效' },
        ]
      },
      info2: {
        title: '保函办理推介服务单位',
        desc: `
          <p>即担保预付款会按照合同要求使用。通常施工企业提供一张预付款保函给业主(开发商、保函受益人)，业主即拨付同等金额的预付款给施工企业。</p>
          <p>担保金额一般为合同价的10%-30%。</p>
          <p>即担保企业签订施工合同后，能按合同履约。</p>
          <p>担保金额一般为中标价或者合同价的10%-30%。</p>
          <p>即担保业主或者施工企业不拖欠农民工工资。通常受益人是工程所在地社保局或者劳动局或者建设局。即工程竣工验收之后，担保工程质量不出问题。通常质量保函的期限是两年，担保金额为合同价的3%-10%。</p>
        `,
        img: require('@/assets/images/svc6_1.jpg')
      }
    };
  },
};
</script>

<style lang="scss" scoped></style>